<template>
  <div id="app-query" class="app-container">
    <keep-alive>
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item :index="0">全部</el-menu-item>
        <el-menu-item :index="3">未启用</el-menu-item>
        <el-menu-item :index="2">离线</el-menu-item>
        <el-menu-item :index="1">在线</el-menu-item>
      </el-menu>
    </keep-alive>
    <div class="session1 clearfix">
      <div class="shopBox" ref="shopBox" @click="treeClick">
        <i>组织：</i>
        <input type="text" readonly name="" id="" :value="checkedName" />
        <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
      </div>
      <div class="treeBox" ref="treeBox" v-show="treeIsShow">
        <el-input placeholder="请输入门店名称搜索" v-model="searchOrg" @change="searchOrg" class="input-with-select">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
        <el-tree
          node-key="orgcode"
          :data="shopsTree"
          :props="defaultProps"
          ref="tree"
          :default-expanded-keys="[orgcode]"
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
          highlight-current="true"
          @node-click="handleNodeClick"
        >
        </el-tree>
        <div class="dialogBtn pt20 mb20 borderTop">
          <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
          <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
        </div>
      </div>
      <el-input
        placeholder="请输入探针编号、MAC-ID进行检索"
        v-model="keyword"
        class="input-with-select ml20"
        @change="getProbeUseList"
      >
        <el-button class="searchbtn" slot="append" icon="el-icon-search" @click="getProbeUseList"></el-button>
      </el-input>
      <div style="position: relative" ref="customDialog" class="fr">
        <a class="downloadstyle bord" :href="downloadurl"><i class="fa fa-download fhd"></i></a>
      </div>
    </div>
    <div class="integratable">
      <el-table
        :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
        row-key="id"
        :cell-class-name="cellstyle"
        @sort-change="sortChange"
        style="width: 100%"
      >
        <el-table-column prop="probecode" sortable="custom" align="right" label="探针编号"> </el-table-column>
        <el-table-column prop="macid" sortable="custom" align="right" label="MAC-ID"> </el-table-column>
        <el-table-column prop="probename" sortable="custom" align="left" label="探针名称"> </el-table-column>
        <el-table-column prop="shopname" sortable="custom" align="left" label="安装门店"> </el-table-column>
        <el-table-column prop="shopno" sortable="custom" align="right" label="门店编号"> </el-table-column>
        <el-table-column prop="usingType" sortable="custom" align="left" label="探针类型">
          <template slot-scope="scope">
            {{ scope.row.usingType === 'count' ? '新零售' : '红外' }}
          </template>
        </el-table-column>
        <el-table-column prop="probestatusstr" sortable="custom" align="left" label="状态"> </el-table-column>
        <el-table-column prop="updatetimestr" sortable="custom" align="right" label="最后更新时间"> </el-table-column>
        <el-table-column label="操作" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button @click="getSeq(scope.row)" type="text" size="small">设置</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flexend">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 30, 50]"
        :page-size="100"
        layout="total,sizes, prev, pager, next"
        :total="tableData.length"
      >
      </el-pagination>
    </div>

    <!--设置探针-->
    <el-dialog
      title="设置探针"
      custom-class="addDialog"
      :visible.sync="dialogFormVisible"
      :before-close="cancelSetCamera"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="探针名称：" :label-width="formLabelWidth" prop="probename">
          <el-input v-model="form.probename" autocomplete="off" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="安装门店：" :label-width="formLabelWidth" prop="shop">
          <div class="shopBox fl" ref="shoptree" @click="setTree">
            <input type="text" readonly name="" id="" :value="form.shop" />
            <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
          </div>
          <div class="treeBox setShops" v-show="shopShow">
            <el-tree
              node-key="orgcode"
              :data="shopsData"
              :props="defaultProps"
              :default-expanded-keys="[shopid]"
              ref="shop"
              :filter-node-method="shopfilterNode"
              :expand-on-click-node="false"
              highlight-current="true"
              @node-click="shopClick"
            >
              <span slot-scope="{ node, data }">
                <span :class="{ 'tree-disabled': data.disabled }">{{ node.label }}</span>
              </span>
            </el-tree>
            <div class="dialogBtn pt20 mb15 borderTop">
              <button @click="getShopOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
              <button @click="this.shopShow = false" type="button" class="ag-model-btn gray fts">取消</button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelSetCamera">取 消</el-button>
        <el-button type="primary" @click="newCamera('ruleForm')">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery'
import treemixin from 'src/mixins/tree'
import { setProbeInfo, getProbeUseList, getprobedevinfo } from 'src/api/legacy'

var storeHttp = '/newretail'

export default {
  mixins: [treemixin],
  data() {
    return {
      loading: true,
      shopsData: [],
      currentPage: 1,
      pageSize: 10,
      activeIndex: 0,
      keyword: '',
      tableData: [],
      dialogFormVisible: false,
      form: {
        probename: '',
        shop: '', //安装门店
      },
      groupcode: '',
      rules: {
        probename: [
          {
            required: true,
            message: '请填写',
            trigger: 'blur',
          },
        ],
        shop: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      shopid: '', //安装门店id
      probeid: '',
      shopShow: false,
      formLabelWidth: '120px',
      order: '',
      prop: '',
    }
  },
  methods: {
    cellstyle({ row, column, rowIndex, columnIndex }) {
      if (row.probestatusstr === '离线' && columnIndex == 6) {
        return 'red'
      }
    },
    handleSelect(key, keyPath) {
      //切换menu
      this.activeIndex = key
      this.currentPage = 1
      this.getProbeUseList()
    },
    shopfilterNode(data) {
      //过滤组织
      if (data.orgType === 2) {
        data.disabled = true
      }
      return data.prmType >= 0 && data.orgcode.indexOf(this.groupcode) === 0
    },
    checkshopFilter() {
      this.$refs.shop.filter(true)
    },
    shopClick(data) {
      //选中门店
      if (data.disabled) return
      this.getTreeOrgcode = data
    },
    getShopOrgcode() {
      if (this.getTreeOrgcode) {
        this.shopid = this.getTreeOrgcode.orgcode
        this.form.shop = this.getTreeOrgcode.name
      }
      this.shopShow = false
    },
    // 设置探针
    newCamera(formName) {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let data = {
            id: this.probeid,
            probename: this.form.probename,
            shopid: this.shopid,
          }
          setProbeInfo(data).then((res) => {
            if (res && res.status === 0) {
              this.dialogFormVisible = false
              this.$message({
                message: '成功',
                type: 'success',
              })
              this.resetForm('ruleForm')
              this.getProbeUseList()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancelSetCamera() {
      this.resetForm('ruleForm')
      this.dialogFormVisible = false
    },
    getProbeUseList() {
      //探针使用管理列表
      let data = {
        probestatus: this.activeIndex,
        orgcode: this.orgcode,
        keyword: this.keyword,
      }
      const loading = this.$loading({
        text: '加载中',
        target: document.querySelector('.el-table__body'),
      })
      getProbeUseList(data).then((res) => {
        if (res && res.status === 0) {
          this.tableData = res.data
          this.currentPage = 1
          // this.tableData.sort((a, b) => {
          //   return a.probestatusstr < b.probestatusstr ? 1 : -1
          // })
          loading.close()
        }
      })
    },
    getSeq(row) {
      //查询指定设备
      this.dialogFormVisible = true
      this.probeid = row.id
      let data = {
        id: this.probeid,
      }
      getprobedevinfo(data).then((res) => {
        if (res && res.status === 0) {
          this.form.probename = res.data.probename
          this.shopid = res.data.orgcode
          this.form.shop = res.data.shopname
          this.groupcode = res.data.ccode
          // dom生成以后过滤门店树
          this.shopsData = this.filterTree(this.arrData, this.shopfilterNode, 'sublist')
          //设置门店树下拉框选中项
          setTimeout(() => {
            this.$refs.shop.setCurrentKey(res.data.orgcode)
          })
        }
      })
    },
    setTree() {
      this.shopShow = true

      const shopbox = $('.setShops .el-tree')
      const selectNode = $('.el-tree-node.is-current.is-focusable')

      setTimeout(() => {
        shopbox.scrollTop(0)
        const t = shopbox.offset().top
        const y = selectNode.offset().top
        const interval = y - t + 'px'
        shopbox.animate(
          {
            scrollTop: interval,
          },
          0
        )
        // setTimeout(() =>{
        //   console.log(33333,$('.setShops .el-tree').scrollTop(),4444,t,5555,y,6666,shopbox,interval)
        // },100)
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    overviewInit() {
      //页面初始化
      this.getProbeUseList()
    },
    sortChange(column) {
      const { prop, order } = column
      this.prop = prop
      this.order = order
      // const order = column.order === 'ascending' ? -1 : 1
      // if (prop === 'updatetimestr') {
      //   this.tableData.sort((a, b) => {
      //     const Aindex = a[prop].lastIndexOf('天前')
      //     const Bindex = b[prop].lastIndexOf('天前')
      //     return Number(a[prop].slice(0, Aindex)) > Number(b[prop].slice(0, Bindex)) ? -order : order
      //   })
      // } else {
      //   this.tableData.sort((a, b) => {
      //     return a[prop] > b[prop] ? -order : order
      //   })
      // }
    },
    sorter(a, b) {
      const aKey = a[this.prop]
      const bKey = b[this.prop]
      const order = this.order === 'ascending' ? 1 : -1
      return aKey > bKey ? order : -order
    },
  },
  watch: {},
  computed: {
    useTable() {
      if (this.order) {
        return this.tableData
          .slice()
          .sort(this.sorter)
          .slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      } else {
        return this.tableData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      }
    },
    downloadurl() {
      return (
        storeHttp +
        '/api/probe/downloadprobeuseinfo?probestatus=' +
        this.activeIndex +
        '&orgcode=' +
        this.orgcode +
        '&keyword=' +
        this.keyword
      )
    },
  },
  created() {},
  mounted() {
    let probeUse = JSON.parse(sessionStorage.getItem('probeUse'))
    const loading = this.$loading({
      text: '加载中',
      target: document.querySelector('#app-query'),
    })
    this.getTreeData()
      .then(() => {
        if (probeUse === null) {
          //缓存
          console.log('没有缓存')
        } else {
          this.activeIndex = probeUse.active
          this.orgcode = probeUse.code !== '' ? probeUse.code : this.orgcode
          this.keyword = probeUse.keyword
          this.checkedName = probeUse.treeName
        }
        this.overviewInit()
        this.$refs.tree.setCurrentKey(this.orgcode)
      })
      .finally(() => {
        loading.close()
      })

    this.checkFilter()
    document.addEventListener('click', (e) => {
      if (this.$refs.shoptree && !this.$refs.shoptree.contains(e.target)) {
        this.shopShow = false
      }
    })

    window.onbeforeunload = (e) => {
      //缓存
      let obj = {
        active: this.activeIndex,
        code: this.orgcode,
        keyword: this.keyword,
        treeName: this.checkedName,
      }
      sessionStorage.setItem('probeUse', JSON.stringify(obj))
    }
  },
}
</script>

<style lang="less" scoped>
.app-container {
  padding-top: 0px;
}
.shopBox {
  display: inline-block;
}
.session1 {
  padding: 20px 20px 0 20px;
  margin: 0 -20px;
  align-items: center;
}

.el-tree {
  max-height: 330px;
  overflow-y: auto;
}

.session4 {
  margin-top: 40px;
  width: 100%;
}
.flexend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}
.popupStyle {
  width: 420px;
  position: absolute;
  top: 33px;
  left: -298px;
  background-color: #ffffff;
  border-radius: 2px;
  z-index: 99;
}
.chooseProp {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.trend-layout-block {
  position: relative;
  padding: 15px;
}
.trend-layout-block label input {
  margin-right: 7px;
}
.popupStyle label {
  width: 128px;
  margin-bottom: 15px;
}
.downloadstyle {
  width: 61px;
  height: 30px;
  position: relative;
  bottom: -1px;
  line-height: 27px;
  border-radius: 2px;
  text-align: center;
  color: #000;
  font-size: 14px;
  background-color: #f4f5f7;
  z-index: 1;
}

.el-select {
  width: 194px;
}
.add-indent label {
  width: 121px;
  height: 16px;
  overflow: hidden;
}

/* 设置自定义列 */
.el-checkbox + .el-checkbox {
  margin-left: 0;
}
.el-checkbox .el-checkbox {
  margin-right: 30px;
}
.ag-custom-column label {
  width: 126px;
  margin-bottom: 14px;
}
.ag-custom-column {
  width: 410px;
  position: absolute;
  top: 42px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 2px;
  z-index: 99;
}
.bor {
  border: 1px solid #d2d2d2;
}
.fts {
  font-size: 12px;
}
.ag-layout-block {
  position: relative;
  padding: 15px;
}
.ag-layout-block.hori {
  padding-top: 0;
  padding-bottom: 0;
}
label {
  display: inline-block;
  line-height: 1.42857;
  font-style: normal;
}
.ag-model-btn {
  font-family: 'Microsoft Yahei', Helvetica, sans-serif;
  min-width: 63px;
  height: 30px;
  padding: 0 10px;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 2px;
}
.ag-model-btn.blue {
  color: #fff;
  border: 1px solid #5c76cc;
  background-color: #5c76cc;
}
.ag-model-btn.gray {
  color: #333;
  background-color: #f4f5f7;
  border: 1px solid #d2d2d2;
}
.ag-model-btn.gray:active {
  border-color: #d2d2d2;
  background-color: #dddee0;
}
.ag-model-btn.gray:hover {
  border-color: #d2d2d2;
  background-color: #fff;
}
.ag-model-btn.blue:active {
  border-color: #5c76cc;
  background-color: #5c76cc;
}
.ag-model-btn.blue:hover {
  border-color: #5c76cc;
  background-color: #5c76cc;
}
.el-select .el-input,
.el-select .el-input .el-input__inner,
.el-form-item__content .el-input .el-input__inner {
  width: 200px;
}
.el-select-dropdown.el-popper {
  min-width: 200px !important;
}
.addDialog .el-input__prefix,
.addDialog .el-input__suffix {
  top: 6px;
}

.el-input-group {
  width: 350px;
}
.el-input-group__append,
.el-input-group__prepend {
  padding: 0 4px;
}

.addDialog .shopBox span {
  top: 12px;
}

.addDialog .treeBox {
  left: 0;
}
.tree-disabled {
  cursor: not-allowed;
  /* background-color: #F5F5F5; */
  color: #aca899;
}
</style>